import Slider from "react-slick";
import "./CarouselComponent.css";

interface CarouselItem {
  carouselCaption: string;
  carouselDescription: string;
  imageUrl: string;
}

interface CarouselComponentProps {
  items: CarouselItem[];
}

function CarouselComponent({ items }: CarouselComponentProps) {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 4000,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>
        {items.map((item, index) => (
          <div className="slider-item" key={index}>
            <img src={item.imageUrl} className="img-fluid w-100" alt="" />
            <div className="carousel-caption">
              <h5
                className="text-white text-uppercase fw-bold mb-4"
                style={{ letterSpacing: "3px" }}
              >
                {item.carouselCaption}
              </h5>
              <h1 className="display-5 text-capitalize text-white mb-4">
                {item.carouselDescription}
              </h1>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default CarouselComponent;
