import React from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
      <Container>
        <Row className="g-5">
          <Col md={6} lg={6} xl={3}>
            <div className="footer-item d-flex flex-column">
              <h4 className="text-white mb-4"><i className="fas fa-star-of-life me-3"></i>AdStuffs</h4>
              <p>"Welcome to AdStuffs, a trailblazing platform at the intersection of hydration and advertising. Our model is simple yet revolutionary: we distribute premium water for free, with the costs covered by brand advertisements featured directly on our eco-friendly bottles."</p>
              {/* <div className="d-flex align-items-center">
                <i className="fas fa-share fa-2x text-white me-2"></i>
                <Button variant="primary" className="text-white rounded-circle mx-1"><i className="fab fa-facebook-f"></i></Button>
                <Button variant="primary" className="text-white rounded-circle mx-1"><i className="fab fa-twitter"></i></Button>
                <Button variant="primary" className="text-white rounded-circle mx-1"><i className="fab fa-instagram"></i></Button>
                <Button variant="primary" className="text-white rounded-circle mx-1"><i className="fab fa-linkedin-in"></i></Button>
              </div> */}
            </div>
          </Col>
          <Col md={6} lg={6} xl={3}>
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Quick Links</h4>
              <Nav.Link as={NavLink} to="/about"><i className="fas fa-angle-right me-2"></i> About Us</Nav.Link>
              <Nav.Link as={NavLink} to="/contact"><i className="fas fa-angle-right me-2"></i> Contact Us</Nav.Link>
              {/* <Nav.Link as={NavLink} to="/privacy-policy"><i className="fas fa-angle-right me-2"></i> Privacy Policy</Nav.Link>
              <Nav.Link as={NavLink} to="/terms-and-conditions"><i className="fas fa-angle-right me-2"></i> Terms & Conditions</Nav.Link> */}
              <Nav.Link as={NavLink} to="/service"><i className="fas fa-angle-right me-2"></i>Services</Nav.Link>
              <Nav.Link as={NavLink} to="/design"><i className="fas fa-angle-right me-2"></i>Design Your Custom Logo</Nav.Link>
              {/* <Nav.Link as={NavLink} to="/our-team"><i className="fas fa-angle-right me-2"></i> Our Team</Nav.Link> */}
            </div>
          </Col>
          <Col md={6} lg={6} xl={3}>
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Advert</h4>
              <Nav.Link as={NavLink} to="/about"><i className="fas fa-angle-right me-2"></i> About us</Nav.Link>
              <Nav.Link as="a" href="#why-choose-us">
                <i className="fas fa-angle-right me-2"></i> Why choose us
              </Nav.Link>
            </div>
          </Col>
          <Col md={6} lg={6} xl={3}>
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Contact Info</h4>
              <Nav.Link href="#"><i className="fa fa-map-marker-alt me-2"></i>WCTM, Farukknagar</Nav.Link>
              <Nav.Link href="#"><i className="fas fa-envelope me-2"></i>admin@adstuffs.com</Nav.Link>
              <Nav.Link href="#"><i className="fas fa-phone me-2"></i>+91 9729256057</Nav.Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
