import { Container, Row, Col } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import './FeatureComponent.css'
import {fadeInUp} from 'react-animations';
import { useInView } from 'react-intersection-observer';

interface FeatureItem {
  title: string;
  description: string;
  iconName: string;
}

interface FeatureProps {
  heading: string,
  subHeading: string,
  items: FeatureItem[],
  id: string
}

interface Feature {
  features: FeatureProps,
}


const fadeInUpAnimation = keyframes`${fadeInUp}`;

const FadeInUpDiv = styled.div`
  animation: 2s ${fadeInUpAnimation};
`;

const FeatureComponent = ( {features} : Feature) => {
  const [ref, inView] = useInView({
    triggerOnce: true, 
    threshold: 0.2, 
  });

  return (
    <div ref={ref} id="" className="container-fluid feature py-5">
      <Container className="py-5">
      {inView && (
        <FadeInUpDiv>
          <div> 
          <div className="section-title mb-5">
            <div className="sub-style">
              <h4 className="sub-title px-3 mb-0">{features.heading}</h4>
            </div>
          </div>
          <h6 className="section-title px-3 mb-0 display-3">{features.subHeading}</h6>
          </div>
        </FadeInUpDiv>
      )}
        <Row className="g-4 justify-content-center">
          {features.items.map((feature: FeatureItem, index: number) => (
            <Col md={6} lg={4} xl={3} key={index}>
              {inView && (
              <FadeInUpDiv style={{ animationDelay: `${0.1 + index * 0.2}s` }}>
                <div className="feature-item p-4 row">
                  <div className="feature-icon mb-4 col-12">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      <i className={`fas ${feature.iconName} fa-4x text-primary`}></i>
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column col-12">
                    <h5 className="mb-4">{feature.title}</h5>
                    <p className="mb-0">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </FadeInUpDiv>
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default FeatureComponent;
