import React, { useState } from "react";

import "./Contact.css";
import contactus from "../../../assets/images/contact-us.webp";
import { Col, Container, Row } from "react-bootstrap";

const MyFormcarryFormURL = "https://formcarry.com/s/NN7m2f0PG-o";

interface FormData {
  name: string;
  email: string;
  message: string;
  contactNumber: string;
}

const Basic: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    message: "",
    contactNumber: "",
  });

  const [error, setError] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const resetStates = (): void => {
    setSubmitted(false);
    setError("");
  };

  const resetForm = (): void => {
    setFormData({
      name: "",
      email: "",
      message: "",
      contactNumber: "",
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    resetStates();

    fetch(MyFormcarryFormURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.code === 200) {
          setSubmitted(true);
          resetForm();
        } else if (response.code === 422) {
          // Field validation failed
          setError(response.message);
        } else {
          // other error from formcarry
          setError(response.message);
        }
      })
      .catch((error) => {
        // request related error.
        setError(error.message ? error.message : error);
      });
  };

  const showNotification: boolean = submitted || error !== "";

  const renderStatus = (): JSX.Element => {
    const message =
      error !== ""
        ? error
        : `Thanks for reaching out!, we'll get back to you soon.`;
    const icon = error !== "" ? "error" : "success";

    return (
      <div className="formcarry-block">
        <div className={`formcarry-message-block fc-${icon} active`}>
          <div className="fc-message-icon"></div>
          <div className="fc-message-content">{message}</div>
          <div className="fc-message-close" onClick={() => resetStates()}></div>
        </div>
      </div>
    );
  };

  return (
    <Container>
      <Row className="contact-us">
        <Col xs={12} md={6}>
          <img
            src={contactus}
            className="img-fluid rounded w-100 contact-us-img"
            alt="About Us"
            style={{ objectFit: "cover" }}
          />{" "}
        </Col>
        <Col xs={12} md={6}>
          <div className="formcarry-container">
            <div className="demo-introduce">
              <h1>Contact Us</h1>
            </div>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="formcarry-block">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  id="name"
                  name="name"
                  placeholder="Your first and last name"
                />
              </div>

              <div className="formcarry-block">
                <label htmlFor="email">Your Email Address</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  id="email"
                  name="email"
                  placeholder="shuklavedika436@gmail.com"
                />
              </div>

              <div className="formcarry-block">
                <label htmlFor="contactnumber">Your Contact Number</label>
                <input
                  type="number"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  id="contactnumber"
                  name="contactNumber"
                  placeholder="9729256067"
                />
              </div>

              <div className="formcarry-block">
                <label htmlFor="message">Your message</label>
                <textarea
                  value={formData.message}
                  onChange={handleChange}
                  id="message"
                  name="message"
                  placeholder="Enter your message..."
                ></textarea>
              </div>

              <div className="formcarry-block">
                <button type="submit">Send</button>
              </div>

              {showNotification && renderStatus()}
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Basic;
