import React from 'react';
import NavBar from './../../components/ui/Navbar';
import Footer from '../../components/ui/Footer';

type PublicLayoutProps = {
    children: React.ReactNode;
};

const PublicLayout:React.FC<PublicLayoutProps> = ({ children }) => {
  return (
    <>
      <NavBar />
      <main>{children}</main>
      {/* You can also include a Footer here if it's common across all public pages */}
      <Footer></Footer>
    </>
  );
};

export default PublicLayout;
