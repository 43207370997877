import React from 'react';
import { LinkContainer } from 'react-router-bootstrap'; // For routing with React Bootstrap components
import { Container, Navbar, Nav } from 'react-bootstrap'; // Importing necessary components
import logo from './../../../assets/images/logo.jpeg'

const NavBar: React.FC = () => {
  return (
    <Navbar bg="light" expand="lg" className="px-4 px-lg-5 py-3 py-lg-0">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <h1 className="text-primary m-0">
              <img src={logo}></img>AdStuffs
            </h1>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/design">
              <Nav.Link>Design</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/about">
              <Nav.Link>About</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/service">
              <Nav.Link>Services</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
