import React, { useState } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { Col, Container, Row } from 'react-bootstrap';
import { fabric } from 'fabric';
import "./Design.css";

interface ImageObject {
  src: string;
  id: string;
}

const ImageCanvas: React.FC = () => {
  const { editor, onReady } = useFabricJSEditor();
  const [images, setImages] = useState<ImageObject[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        setImages([...images, { src: e.target?.result as string, id: `image-${images.length}` }]);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const imgSrc = event.dataTransfer.getData("text");
    fabric.Image.fromURL(imgSrc, (img) => {
      editor?.canvas.add(img);
      editor?.canvas.renderAll();
    });
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <Container className="main-content"> {/* Added the main-content class here */}
      <Row>
        <Col xs={3}>
          <input type="file" onChange={handleImageUpload} multiple />
          {images.map((img) => (
            <img
              key={img.id}
              src={img.src}
              alt="Uploaded"
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("text/plain", img.src);
              }}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          ))}
        </Col>
        <Col xs={9} onDrop={handleDrop} onDragOver={handleDragOver}>
          <FabricJSCanvas className="sample-canvas large-canvas" onReady={onReady} /> {/* Added the large-canvas class here */}
        </Col>
      </Row>
    </Container>
  );
};

export default ImageCanvas;
