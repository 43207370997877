import { Container, Row, Col } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import aboutImage from "../../../assets/images/about-image.webp";
import { fadeInLeft } from "react-animations";
import { fadeInRight } from "react-animations";
import "./About.css";
import { useInView } from "react-intersection-observer";
import car1 from "../../../assets/images/car-1.webp";
import FeatureComponent from "../../../lib/ui/FeatureComponent";

// Animation keyframes
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

// Styled components for animations
const FadeInLeftDiv = styled.div`
  animation: 2s ease ${fadeInLeftAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 2s ease ${fadeInRightAnimation};
`;

const features = [
  {
    title: "Innovation",
    description:
      "We continuously push boundaries, creating revolutionary solutions that redefine the advertising and beverage landscape.",
    iconName: "fa-sort-amount-up",
  },
  {
    title: "Sustainability",
    description:
      "Our dedication to sustainability drives every decision, ensuring our practices minimize environmental impact and promote eco-consciousness.",
    iconName: "fa-pager",
  },
  {
    title: "Collaboration",
    description:
      "We believe in the power of collaboration, forging strong partnerships with brands and consumers to drive collective change and progress.",
    iconName: "fa-users",
  },
];

const feature = {
  heading: "Core Values",
  subHeading:
    "Guiding principles driving our commitment to innovation and sustainability",
  items: features,
  id: ""
};

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <div>
      <div className="slider-item">
        <img src={car1} className="img-fluid w-100 img-about-size" alt="" />
        <div className="carousel-caption">
          <h5
            className="text-white text-uppercase fw-bold mb-4"
            style={{ letterSpacing: "3px" }}
          >
            AdStuffs
          </h5>
          <h1 className="display-5 text-capitalize text-white mb-4">
            Where Creativity Meets Impact
          </h1>
        </div>
      </div>
      <div className="container-fluid about bg-light py-5">
        <Container ref={ref} className="py-5">
          {inView && (
            <Row className="g-5 align-items-center">
              <Col lg={5}>
                <FadeInLeftDiv>
                  <div className="about-img">
                    <img
                      src={aboutImage}
                      className="img-fluid rounded w-100"
                      alt="About Us"
                      style={{ objectFit: "cover" }}
                    />
                    {/* Uncomment and update the src if you need the inner image */}
                    {/* <div className="about-img-inner">
                  <img src="img/about-2.jpg" className="img-fluid rounded-circle w-100 h-100" alt="Inner Image" />
                </div> */}
                  </div>
                </FadeInLeftDiv>
              </Col>
              <Col lg={7}>
                <FadeInRightDiv>
                  <div className="section-title text-start mb-5">
                    <h4 className="sub-title pe-3 mb-0">About Us</h4>
                    <h1 className="display-3 mb-4">
                      We are here to Help you grow your brand.
                    </h1>
                    <p className="mb-4">
                      At AdStuffs, we're leading the charge in sustainable
                      advertising, bridging the gap between brands and consumers
                      with our innovative platform. Our vision is to blend
                      hydration and advertising seamlessly, fostering a
                      symbiotic relationship that prioritizes sustainability.
                    </p>
                    <p className="mb-4">
                      Founded on this principle, AdStuffs has become synonymous
                      with cutting-edge marketing solutions that resonate with
                      today's eco-conscious consumers. We specialize in
                      providing brands with eco-friendly advertising options
                      that leave a lasting impression while minimizing
                      environmental impact.
                    </p>
                    <p className="mb-4">
                      Our commitment to sustainability runs deep, driving us to
                      leverage environmentally friendly materials and practices
                      in all our endeavors. With AdStuffs, brands across diverse
                      industries have discovered new avenues to engage their
                      audiences while championing sustainability.
                    </p>
                    <p className="mb-4">
                      Join us in redefining advertising by promoting sustainable
                      practices and fostering meaningful connections between
                      brands and consumers. Together, let's pave the way for a
                      brighter, greener future in advertising.
                    </p>
                  </div>
                </FadeInRightDiv>
              </Col>
            </Row>
          )}
          <FeatureComponent features={feature}></FeatureComponent>
        </Container>
      </div>
    </div>
  );
};

export default About;
