import { Container, Row, Col } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import aboutImage from "../../../assets/images/about-image.webp";
import { fadeInLeft } from "react-animations";
import { fadeInRight } from "react-animations";
import { useInView } from "react-intersection-observer";

const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

// Styled components for animations
const FadeInLeftDiv = styled.div`
  animation: 2s ease ${fadeInLeftAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 2s ease ${fadeInRightAnimation};
`;
const AboutUsHome = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <div className="container-fluid about bg-light py-5">
      <Container ref={ref} className="py-5">
        {inView && (
          <Row className="g-5 align-items-center">
            <Col lg={5}>
              <FadeInLeftDiv>
                <div className="about-img">
                  <img
                    src={aboutImage}
                    className="img-fluid rounded w-100"
                    alt="About Us"
                    style={{ objectFit: "cover" }}
                  />
                  {/* Uncomment and update the src if you need the inner image */}
                  {/* <div className="about-img-inner">
                  <img src="img/about-2.jpg" className="img-fluid rounded-circle w-100 h-100" alt="Inner Image" />
                </div> */}
                </div>
              </FadeInLeftDiv>
            </Col>
            <Col lg={7}>
              <FadeInRightDiv>
                <div className="section-title text-start mb-5">
                  <h4 className="sub-title pe-3 mb-0">About Us</h4>
                  <h1 className="display-3 mb-4">
                    We are here to Help you grow your brand.
                  </h1>
                  <p className="mb-4">
                    At AdStuffs, we're leading the charge in sustainable
                    advertising, bridging the gap between brands and consumers
                    with our innovative platform. Our vision is to blend
                    hydration and advertising seamlessly, fostering a symbiotic
                    relationship that prioritizes sustainability.
                  </p>
                </div>
              </FadeInRightDiv>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default AboutUsHome;
