import { Container, Row, Col, Card } from "react-bootstrap";
import styled, { keyframes } from "styled-components";
import custombrand from "../../../assets/images/custom-brand.webp";
import customdesign from "../../../assets/images/custom-design.webp";
import distribute from "../../../assets/images/distribute.webp";
import highengegement from "../../../assets/images/high-engagement.jpg";
import { fadeInLeft, fadeInRight, fadeInUp } from "react-animations";
import "./Services.css";
import { useInView } from "react-intersection-observer";
import car1 from "../../../assets/images/car-1.webp";

// Animation keyframes
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

// Styled components for animations
const FadeInLeftDiv = styled.div`
  animation: 2s ease ${fadeInLeftAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 2s ease ${fadeInRightAnimation};
`;

const Animated2sDiv = styled.div`
  animation: 2s ease ${fadeInUpAnimation};
`;
const Animated1sDiv = styled.div`
  animation: 2s ease ${fadeInUpAnimation};
`;
const Animated3sDiv = styled.div`
  animation: 2.5s ease ${fadeInUpAnimation};
`;
const Animated5sDiv = styled.div`
  animation: 3s ease ${fadeInUpAnimation};
`;
const Animated7sDiv = styled.div`
  animation: 3.5s ease ${fadeInUpAnimation};
`;

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const [ref2, inViewRef2] = useInView({
    triggerOnce: true,
    threshold: 0.9,
  });
  return (
    <div>
      <div className="slider-item">
        <img src={car1} className="img-fluid w-100 img-about-size" alt="" />
        <div className="carousel-caption">
          <h5
            className="text-white text-uppercase fw-bold mb-4"
            style={{ letterSpacing: "3px" }}
          >
            Services
          </h5>
          <h1 className="display-5 text-capitalize text-white mb-4">
            "Your Message, Their Hydration - Advertise Effectively on Every
            Bottle!"
          </h1>
        </div>
      </div>
      <div ref={ref} className="service py-5">
        <Container className="py-5">
          {inView && (
            <Animated2sDiv className="section-title mb-5">
              <div className="sub-style">
                <h4 className="sub-title px-3 mb-0">What We Do</h4>
              </div>
              <h1 className="display-3 mb-4">
                Water that nourishes, labels that advertise - a winning combo!
              </h1>
            </Animated2sDiv>
          )}
          {inView && (
            <Row className="g-4 justify-content-center">
              <Col md={6} lg={4} xl={3}>
                <Animated1sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={custombrand}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>Custom Bottle Branding</Card.Title>
                      <Card.Text>
                        Impress your customers by showcasing your brand logo
                        prominently on our mineral water bottles. Elevate your
                        brand image effortlessly and leave a lasting impression
                        on your clientele
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated1sDiv>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Animated3sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={distribute}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>Strategic Distribution</Card.Title>
                      <Card.Text>
                        Targeted placement at events and venus Hands-on
                        logistics and distribution network.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated3sDiv>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Animated5sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={customdesign}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>Creative Design Solutions</Card.Title>
                      <Card.Text>
                        Collaborative process to ensure brand alignment.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated5sDiv>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Animated7sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={highengegement}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>Community Engagement</Card.Title>
                      <Card.Text>
                        Initiatives that connect with local audiences.Promotions
                        that give back to the community.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated7sDiv>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Animated7sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={highengegement}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>Festive Customization</Card.Title>
                      <Card.Text>
                        Customize labels for special occasions and festive
                        seasons. Whether it's Diwali, Christmas, or New Year,
                        align your brand with the spirit of celebration and
                        captivate your audience with themed labels.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated7sDiv>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Animated7sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={highengegement}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>
                        Offers to Drive Customer Engagement and Loyalty:
                      </Card.Title>
                      <Card.Text>
                        Enhance customer loyalty and drive repeat business by
                        offering exclusive deals and promotions directly on the
                        bottle labels. Encourage customers to return and engage
                        with your brand by enticing them with special discounts
                        and offers.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated7sDiv>
              </Col>
              <Col md={6} lg={4} xl={3}>
                <Animated7sDiv>
                  <Card className="service-item rounded">
                    <div className="service-img rounded-top">
                      <Card.Img
                        variant="top"
                        src={highengegement}
                        className="img-fluid rounded-top w-100"
                      />
                    </div>
                    <Card.Body className="service-content rounded-botton bg-light p-4">
                      <Card.Title>
                        QR Code Integration for Increased Website Traffic
                      </Card.Title>
                      <Card.Text>
                        Generate and attach QR codes to your water bottles to
                        boost website traffic and visibility. Drive customer
                        engagement and recognition by providing easy access to
                        your online platforms, promotions, and exclusive offers.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Animated7sDiv>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Container ref={ref2}>
        <div className="offering-title">
          <h4 className="display-3">Our Offerings</h4>
        </div>
        {inViewRef2 && (
          <div>
            <Row className="g-4 justify-content-center align-items-center">
              <Col md={6} lg={6} xl={6}>
                <FadeInLeftDiv>
                  <img
                    src={custombrand}
                    className="img-fluid offer-img-size rounded"
                  />
                </FadeInLeftDiv>
              </Col>

              <Col md={6} lg={6} xl={6}>
                <FadeInRightDiv>
                  <h3>Branded Bottles</h3>
                  <p>
                    {" "}
                    Our branded bottles feature cutting-edge designs that
                    showcase your logo, serving as a mobile billboard while
                    providing refreshing hydration with each sip.
                  </p>
                </FadeInRightDiv>
              </Col>
            </Row>
            <Row className="g-4 justify-content-center align-items-center">
              <Col md={6} lg={6} xl={6}>
                <FadeInLeftDiv>
                  <h3>Hydration Campaigns</h3>
                  <p>
                    {" "}
                    Engage with our tailored hydration campaigns that blend
                    advertising creativity with the essence of water, connecting
                    your brand with consumers in a meaningful and refreshing
                    way.
                  </p>
                </FadeInLeftDiv>
              </Col>
              <Col md={6} lg={6} xl={6}>
                <FadeInRightDiv>
                  <img
                    src={custombrand}
                    className="img-fluid offer-img-size rounded"
                  />
                </FadeInRightDiv>
              </Col>
            </Row>
            <Row className="g-4 justify-content-center align-items-center">
              <Col md={6} lg={6} xl={6}>
                <FadeInLeftDiv>
                  <img
                    src={custombrand}
                    className="img-fluid offer-img-size  rounded"
                  />
                </FadeInLeftDiv>
              </Col>
              <Col md={6} lg={6} xl={6}>
                <FadeInRightDiv>
                  <h3>Brand Amplification</h3>
                  <p>
                    {" "}
                    Connect with diverse audiences and enhance brand visibility
                    through our innovative advertising platform that offers free
                    premium water.
                  </p>
                </FadeInRightDiv>
              </Col>
            </Row>
          </div>
        )}
      </Container>
    </div>
  );
};

export default About;
